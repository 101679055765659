var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.previewData)?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('a-button',{staticClass:"mr-3",attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.closePreview()}}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v("Back")],1),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.previewData.nama)+" Questions Preview")])],1),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Level")]),_c('div',[_vm._v(_vm._s(_vm.previewData.level.nama))])]),_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Subject")]),_c('div',[_vm._v(_vm._s(_vm.previewData.mata_pelajaran.nama))])]),_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Total Question")]),_c('div',[_vm._v(_vm._s(_vm.previewData.paket_soals.length)+" question(s)")])]),_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Multiple Choice")]),_c('div',[_vm._v(_vm._s(_vm.previewData.paket_soals.filter(s => s.tipe === 'Multiple Choice').length)+" question(s)")])]),_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Essay")]),_c('div',[_vm._v(_vm._s(_vm.previewData.paket_soals.filter(s => s.tipe === 'Essay').length)+" question(s)")])])]),_c('div',[_c('ListSoalCbt',{attrs:{"listSoal":_vm.previewData.paket_soals}})],1)]):[_c('div',{staticClass:"d-md-flex align-items center"},[_c('a-input',{staticClass:"mr-3",staticStyle:{"min-width":"150px","max-width":"300px"},style:({
          display: _vm.isMobile ? 'block' : null
        }),attrs:{"size":"large","placeholder":"Search by name..."},on:{"change":_vm.fetchDataTable},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('a-select',{class:!_vm.isMobile ? 'mr-3' : 'mb-3',staticStyle:{"min-width":"150px"},style:({
          display: _vm.isMobile ? 'block' : null
        }),attrs:{"size":"large"},model:{value:(_vm.idMapel),callback:function ($$v) {_vm.idMapel=$$v},expression:"idMapel"}},_vm._l((_vm.subjects),function(subject){return _c('a-select-option',{key:subject.id,attrs:{"value":subject.id}},[_vm._v(_vm._s(subject.nama))])}),1),_c('a-select',{class:_vm.isMobile ? 'mb-3' : null,staticStyle:{"min-width":"150px"},style:({
          display: _vm.isMobile ? 'block' : null
        }),attrs:{"size":"large"},model:{value:(_vm.idLevel),callback:function ($$v) {_vm.idLevel=$$v},expression:"idLevel"}},_vm._l((_vm.levels),function(level){return _c('a-select-option',{key:level.id,attrs:{"value":level.id}},[_vm._v(_vm._s(level.nama))])}),1)],1),_c('hr'),(_vm.datas.length)?_c('div',_vm._l((_vm.datas),function(question,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('div',{staticClass:"font-weight-bold font-size-16"},[_vm._v(_vm._s(question.nama))]),_c('div',{staticClass:"text-gray-5 font-size-14"},[_vm._v(_vm._s(question.total_soal)+" question(s) | "+_vm._s(question.tipe || '-'))])]),_c('div',{staticClass:"d-flex"},[_c('a-button',{staticClass:"mr-2",attrs:{"size":"large","type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.chooseQuestionBank(question)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Choose")],1),_c('a-button',{attrs:{"size":"large"},on:{"click":function($event){$event.preventDefault();return _vm.showPreview(question)}}},[_c('a-icon',{attrs:{"type":"file-search"}}),_vm._v("Preview")],1)],1)]),(i !== _vm.datas.length - 1)?_c('hr'):_vm._e()])}),0):_c('EmptyState',{attrs:{"heading":"No Data"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }