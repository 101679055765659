<template>
  <div>
    <div v-if="previewData">
      <div class="d-flex align-items-center">
        <a-button @click.prevent="closePreview()" class="mr-3" type="primary"><a-icon type="arrow-left" />Back</a-button>
        <div class="font-weight-bold">{{previewData.nama}} Questions Preview</div>
      </div>
      <hr />
      <div class="d-flex justify-content-between">
        <div class="mr-3">
          <div class="font-weight-bold">Level</div>
          <div>{{previewData.level.nama}}</div>
        </div>
        <div class="mr-3">
          <div class="font-weight-bold">Subject</div>
          <div>{{previewData.mata_pelajaran.nama}}</div>
        </div>
        <div class="mr-3">
          <div class="font-weight-bold">Total Question</div>
          <div>{{previewData.paket_soals.length}} question(s)</div>
        </div>
        <div class="mr-3">
          <div class="font-weight-bold">Multiple Choice</div>
          <div>{{previewData.paket_soals.filter(s => s.tipe === 'Multiple Choice').length}} question(s)</div>
        </div>
        <div>
          <div class="font-weight-bold">Essay</div>
          <div>{{previewData.paket_soals.filter(s => s.tipe === 'Essay').length}} question(s)</div>
        </div>
      </div>
      <div>
        <ListSoalCbt :listSoal="previewData.paket_soals" />
      </div>
    </div>
    <template v-else>
      <div class="d-md-flex align-items center">
        <a-input
          size="large"
          :style="{
            display: isMobile ? 'block' : null
          }"
          @change="fetchDataTable"
          v-model="searchData"
          class="mr-3"
          style="min-width: 150px; max-width: 300px"
          placeholder='Search by name...'
        />
        <a-select
          :style="{
            display: isMobile ? 'block' : null
          }"
          :class="!isMobile ? 'mr-3' : 'mb-3'"
          style="min-width: 150px"
          v-model="idMapel"
          size="large"
        >
          <a-select-option
            v-for="subject in subjects"
            :value="subject.id"
            :key="subject.id"
            >{{ subject.nama }}</a-select-option
          >
        </a-select>
        <a-select
          style="min-width: 150px"
          :style="{
            display: isMobile ? 'block' : null
          }"
          :class="isMobile ? 'mb-3' : null"
          v-model="idLevel"
          size="large"
        >
          <a-select-option
            v-for="level in levels"
            :value="level.id"
            :key="level.id"
            >{{ level.nama }}</a-select-option
          >
        </a-select>
      </div>
      <hr />
      <div v-if="datas.length">
        <div v-for="(question, i) in datas" :key="i">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="font-weight-bold font-size-16">{{question.nama}}</div>
              <div class="text-gray-5 font-size-14">{{question.total_soal}} question(s) | {{question.tipe || '-'}}</div>
            </div>
            <div class="d-flex">
              <a-button size="large" class="mr-2" type="primary" @click.prevent="chooseQuestionBank(question)"><a-icon type="plus" />Choose</a-button>
              <a-button size="large" @click.prevent="showPreview(question)"><a-icon type="file-search" />Preview</a-button>
            </div>
          </div>
          <hr v-if="i !== datas.length - 1" />
        </div>
      </div>
      <EmptyState heading="No Data" v-else />
    </template>
  </div>
</template>

<script>
const ListSoalCbt = () => import('@/components/app/CbtGuru/ListSoalCbt')
const EmptyState = () => import('@/components/app/EmptyState')

export default {
  components: { ListSoalCbt, EmptyState },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      idMapel: null,
      idLevel: null,
      datas: [],
      previewData: null,
      levels: [],
      pagination: {},
      searchData: null,
    }
  },
  methods: {
    chooseQuestionBank(data) {
      this.$confirm({
        title: 'Confirmation',
        content: (
          <div>Are you sure you want to choose this question template?</div>
        ),
        onOk: () => {
          this.$emit('chooseQuestionBank', data)
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    async showPreview(data = {}) {
      try {
        const payload = await this.$store.dispatch('bankSoal/FETCH_QUESTION_BANK_BY_ID', { id: data.id })
        this.previewData = {
          ...payload,
          paket_soals: payload.paket_soals.map(soal => {
            return {
              ...soal,
              bobot_soal: soal.bobot,
              tipe_soal: soal.tipe,
              jawaban: soal.jawaban ? JSON.parse(soal.jawaban) : null,
            }
          }),
        }
      } catch (err) {
        console.log(err)
      }
    },
    closePreview() {
      this.previewData = null
    },
    async fetchLevels() {
      try {
        this.levels = await this.$store.dispatch('master/FETCH_LEVEL', {})
        if (this.levels.length) this.idLevel = this.levels[0].id
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataTable() {
      try {
        const { idMapel, idLevel, searchData: search } = this
        const { data = [], total = 0 } = await this.$store.dispatch('bankSoal/FETCH_ALL_BANK_SOAL', { idMapel, idLevel, page: 'all', search })
        this.datas = data.map(dat => ({ ...dat, key: dat.id }))
        this.pagination = { ...this.pagination, total }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  async mounted() {
    try {
      await this.fetchLevels()
      if (this.subjects.length) this.idMapel = this.subjects[0].id
      this.fetchDataTable()
    } catch (err) {
      console.log(err)
    }
  },
}
</script>

<style>

</style>
